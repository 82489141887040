const SERVICES = [
  {
    value: 'bilbayt',
    title: 'Bilbayt',
    pattern: /^((https?):\/\/)(www.)?(bilbayt)\.com/,
    isAdditionalSource: true,
    validatedUrl: 'https://www.bilbayt.com'
  },
  {
    value: 'zomato',
    title: 'Zomato',
    pattern: /^((https?):\/\/)(www.)?(zomato)\./,
    isAdditionalSource: false,
    validatedUrl: 'https://www.zomato...'
  },
  {
    value: 'google',
    title: 'Google',
    pattern: /^https:\/\/www\.google\.com\/maps\?cid=.+$/,
    patternOld: /^((https?):\/\/)(www.)?(maps.)?(google)(.maps)?\.com\/?(maps)?\?cid=.+$/,
    alternatePattern: /^((https?):\/\/)(www.)?(google)\.com\/(maps)\/(place)\/*/,
    validatedUrl: 'https://www.google.com/maps?cid, https://maps.google.com/?cid or https://www.google.com/maps/place/'
  },
  {
    value: 'foursquare',
    title: 'Foursquare',
    pattern: /^((https?):\/\/)(www.)?(foursquare)\./,
    isAdditionalSource: false,
    validatedUrl: 'https://www.foursquare...'
  },
  {
    value: 'facebook',
    title: 'Facebook',
    pattern: /^((https?):\/\/)(www.)?(facebook)\./,
    isAdditionalSource: false,
    validatedUrl: 'https://www.facebook.'
  },
  {
    value: 'tripadvisor',
    title: 'TripAdvisor',
    pattern: /^((https?):\/\/)(www.)?(tripadvisor)\./,
    isAdditionalSource: false,
    validatedUrl: 'https://www.tripadvisor.'
  },
  {
    value: 'talabat',
    title: 'Talabat',
    pattern: /^((https?):\/\/)(www.)?(talabat)\./,
    isAdditionalSource: false,
    validatedUrl: 'https://www.talabat.'
  },
  {
    value: 'hungerStation',
    title: 'HungerStation',
    pattern: /^((https?):\/\/)(www.)?(hungerstation)\./,
    isAdditionalSource: false,
    validatedUrl: 'https://www.hungerstation.'
  },
  {
    value: 'reserveOut',
    title: 'ReserveOut',
    pattern: /^((https?):\/\/)(www.)?(reserveout)\./, //https://www.reserveout.com
    isAdditionalSource: false,
    validatedUrl: 'https://www.reserveout.'
  },
  {
    value: 'booking',
    title: 'Booking',
    pattern: /^((https?):\/\/)(www.)?(booking)\./, // https://www.booking.com
    isAdditionalSource: false,
    validatedUrl: 'https://www.booking.'
  },
  {
    value: 'expedia',
    title: 'Expedia',
    pattern: /^((https?):\/\/)(www.)?(expedia)\./, //https://www.expedia.com
    isAdditionalSource: false,
    validatedUrl: 'https://www.expedia.'
  },
  {
    value: 'feedback',
    title: 'Feedback',
    isAdditionalSource: false
    // validatedUrl: 'https://www.facebook.com'
  },
  {
    value: 'ubereats',
    title: 'UberEats',
    pattern: /^((https?):\/\/)(www.)?(ubereats)\./, //https://www.merchants.ubereats.com
    isAdditionalSource: true,
    validatedUrl: 'https://www.ubereats.'
  },
  {
    value: 'deliveroo',
    title: 'Deliveroo',
    pattern: /^((https?):\/\/)(www.)?(deliveroo)\./, //https://deliveroo.co.uk/
    isAdditionalSource: true,
    validatedUrl: 'https://www.deliveroo.'
  },
  {
    value: 'Agoda',
    title: 'Agoda',
    pattern: /^((https?):\/\/)(www.)?(agoda)\./,
    isAdditionalSource: true,
    validatedUrl: 'https://www.agoda.'
  },
  {
    value: 'Avvo',
    title: 'Avvo',
    pattern: /^((https?):\/\/)(www.)?(avvo)\./,
    isAdditionalSource: true,
    validatedUrl: 'https://www.avvo.'
  },
  {
    value: 'Google Playstore',
    title: 'Google Playstore',
    pattern: /^((https?):\/\/)(www.)?(play)\.(google)\./,
    isAdditionalSource: true,
    validatedUrl: 'https://www.play.google.'
  },
  {
    value: 'Google Shopping',
    title: 'Google Shopping',
    pattern: /^((https?):\/\/)(www.)?(google-)(shopping)\./,
    isAdditionalSource: true,
    validatedUrl: 'https://www.google-shopping.'
  },
  {
    value: 'Yelp',
    title: 'Yelp',
    pattern: /^((https?):\/\/)(www.)?(yelp)\./,
    isAdditionalSource: true,
    validatedUrl: 'https://www.yelp.'
  },
  {
    value: 'Zillow',
    title: 'Zillow',
    pattern: /^((https?):\/\/)(www.)?(zillow)\./,
    isAdditionalSource: true,
    validatedUrl: 'https://www.zillow.'
  },
  {
    value: 'Airbnb',
    title: 'Airbnb',
    pattern: /^((https?):\/\/)(www.)?(airbnb)\./,
    isAdditionalSource: true,
    validatedUrl: 'https://www.airbnb.'
  },
  {
    value: 'AliExpress',
    title: 'AliExpress',
    pattern: /^((https?):\/\/)(www.)?(aliexpress)\./,
    isAdditionalSource: true,
    validatedUrl: 'https://www.aliexpress.'
  },
  {
    value: 'AlternativeTo',
    title: 'AlternativeTo',
    pattern: /^((https?):\/\/)(www.)?(alternativeto)\./,
    isAdditionalSource: true,
    validatedUrl: 'https://www.alternativeto.'
  },
  {
    value: 'Amazon US',
    title: 'Amazon US',
    pattern: /^((https?):\/\/)(www.)?(amazon)\./,
    isAdditionalSource: true,
    validatedUrl: 'https://www.amazon.'
  },
  {
    value: 'Amazon France',
    title: 'Amazon France',
    pattern: /^((https?):\/\/)(www.)?(amazon)\./,
    isAdditionalSource: true,
    validatedUrl: 'https://www.amazon.'
  },
  {
    value: 'Apartmentratings',
    title: 'Apartmentratings',
    pattern: /^((https?):\/\/)(www.)?(apartmentratings)\./,
    isAdditionalSource: true,
    validatedUrl: 'https://www.apartmentratings.'
  },
  {
    value: 'Apartments',
    title: 'Apartments',
    pattern: /^((https?):\/\/)(www.)?(apartments)\./, //https://www.apartments./
    isAdditionalSource: true,
    validatedUrl: 'https://www.apartments.'
  },
  {
    value: 'Apple Appstore',
    title: 'Apple Appstore',
    pattern: /^((https?):\/\/)(www.)?(apps.apple)\./, //https://www.apps.apple./
    isAdditionalSource: true,
    validatedUrl: 'https://www.apps.apple.'
  },
  {
    value: 'BBB',
    title: 'BBB',
    pattern: /^((https?):\/\/)(www.)?(bbb)\./, //https://www.bbb.org/
    isAdditionalSource: true,
    validatedUrl: 'https://www.bbb.'
  },
  {
    value: 'BookATable',
    title: 'BookATable',
    pattern: /^((https?):\/\/)(www.)?(bookatable)\./, //https://www.bookatable.co.uk/
    isAdditionalSource: true,
    validatedUrl: 'https://www.bookatable.'
  },
  {
    value: 'Capterra',
    title: 'Capterra',
    pattern: /^((https?):\/\/)(www.)?(capterra)\./, //https://www.capterra.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.capterra.'
  },
  {
    value: 'CarGurus',
    title: 'CarGurus',
    pattern: /^((https?):\/\/)(www.)?(cargurus)\./, //https://www.cargurus.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.cargurus.'
  },
  {
    value: 'Careem NOW',
    title: 'Careem NOW',
    pattern: /^((https?):\/\/)(www.)?(careem)\./, //https://www.careem.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.careem.com'
  },
  {
    value: 'Cars_com',
    title: 'Cars.com',
    pattern: /^((https?):\/\/)(www.)?(cars)\./, //https://www.cars.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.cars.'
  },
  {
    value: 'Citysearch',
    title: 'Citysearch',
    pattern: /^((https?):\/\/)(www.)?(citysearch)\./, //http://www.citysearch.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.citysearch.'
  },
  {
    value: 'Classpass',
    title: 'Classpass',
    pattern: /^((https?):\/\/)(www.)?(classpass)\./, //https://classpass.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.classpass.'
  },
  {
    value: 'Consumer Affairs',
    title: 'Consumer Affairs',
    pattern: /^((https?):\/\/)(www.)?(consumeraffairs)\./, //https://www.consumeraffairs.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.consumeraffairs.'
  },
  {
    value: 'CreditKarma',
    title: 'CreditKarma',
    pattern: /^((https?):\/\/)(www.)?(creditkarma)\./, //https://www.creditkarma.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.creditkarma.'
  },
  {
    value: 'CustomerLobby',
    title: 'CustomerLobby',
    pattern: /^((https?):\/\/)(www.)?(customerlobby)\./, //https://www.customerlobby.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.customerlobby.'
  },
  {
    value: 'DealerRater',
    title: 'DealerRater',
    pattern: /^((https?):\/\/)(www.)?(dealerrater)\./, //https://www.dealerrater.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.dealerrater.'
  },
  {
    value: 'Ebay',
    title: 'Ebay',
    pattern: /^((https?):\/\/)(www.)?(ebay)\./, //https://www.ebay.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.ebay.'
  },
  {
    value: 'Edmunds',
    title: 'Edmunds',
    pattern: /^((https?):\/\/)(www.)?(edmunds)\./, //https://www.edmunds.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.edmunds.'
  },
  {
    value: 'Etsy',
    title: 'Etsy',
    pattern: /^((https?):\/\/)(www.)?(etsy)\./, //https://www.etsy.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.etsy.'
  },
  {
    value: 'FindLaw',
    title: 'FindLaw',
    pattern: /^((https?):\/\/)(www.)?(findlaw)\./, //https://www.findlaw.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.findlaw.'
  },
  {
    value: 'G2Crowd',
    title: 'G2Crowd',
    pattern: /^((https?):\/\/)(www.)?(g2)\./, //https://www.g2.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.g2.'
  },
  {
    value: 'Gartner',
    title: 'Gartner',
    pattern: /^((https?):\/\/)(www.)?(gartner)\./, //https://www.gartner.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.gartner.'
  },
  {
    value: 'Glassdoor',
    title: 'Glassdoor',
    pattern: /^((https?):\/\/)(www.)?(glassdoor)\./, //https://www.glassdoor.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.glassdoor.'
  },
  {
    value: 'Greatschools',
    title: 'Greatschools',
    pattern: /^((https?):\/\/)(www.)?(greatschools)\./, //https://www.greatschools.org/
    isAdditionalSource: true,
    validatedUrl: 'https://www.greatschools.'
  },
  {
    value: 'Healthgrades',
    title: 'Healthgrades',
    pattern: /^((https?):\/\/)(www.)?(healthgrades)\./, //https://www.healthgrades.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.healthgrades.'
  },
  {
    value: 'HomeAdvisor',
    title: 'HomeAdvisor',
    pattern: /^((https?):\/\/)(www.)?(homeadvisor)\./, //https://www.homeadvisor.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.homeadvisor.'
  },
  {
    value: 'HomeAway',
    title: 'HomeAway',
    pattern: /^((https?):\/\/)(www.)?(homeaway)\./, //https://www.homeaway.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.homeaway.'
  },
  {
    value: 'Homestars',
    title: 'Homestars',
    pattern: /^((https?):\/\/)(www.)?(homestars)\./, //https://homestars.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.homestars.'
  },
  {
    value: 'Houzz',
    title: 'Houzz',
    pattern: /^((https?):\/\/)(www.)?(houzz)\./, //http://houzz.com.ua/
    isAdditionalSource: true,
    validatedUrl: 'https://www.houzz.'
  },
  {
    value: 'Hotels_com',
    title: 'Hotels.com',
    pattern: /^((https?):\/\/)(www.)?(hotels)\./, //https://hotels.com/ //need to check
    isAdditionalSource: true,
    validatedUrl: 'https://www.hotels.'
  },
  {
    value: 'Indeed',
    title: 'Indeed',
    pattern: /^((https?):\/\/)(www.)?(indeed)\./, //https://indeed.com/  //need to check
    isAdditionalSource: true,
    validatedUrl: 'https://indeed.'
  },
  {
    value: 'Influenster',
    title: 'Influenster',
    pattern: /^((https?):\/\/)(www.)?(influenster)\./, //https://www.influenster.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.influenster.'
  },
  {
    value: 'Insider Pages',
    title: 'Insider Pages',
    pattern: /^((https?):\/\/)(www.)?(insiderpages)\./, //https://www.insiderpages.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.insiderpages.'
  },
  {
    value: 'IT Central Station',
    title: 'IT Central Station',
    pattern: /^((https?):\/\/)(www.)?(itcentralstation)\./, //https://www.itcentralstation.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.itcentralstation.'
  },
  {
    value: 'Lawyers',
    title: 'Lawyers.com',
    pattern: /^((https?):\/\/)(www.)?(lawyers)\./, //https://www.lawyers.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.lawyers.'
  },
  {
    value: 'Lending Tree',
    title: 'Lending Tree',
    pattern: /^((https?):\/\/)(www.)?(lendingtree)\./, //https://www.lendingtree.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.lendingtree.'
  },
  {
    value: 'Martindale',
    title: 'Martindale',
    pattern: /^((https?):\/\/)(www.)?(martindale)\./, // https://www.martindale.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.martindale.'
  },
  {
    value: 'Niche',
    title: 'Niche',
    pattern: /^((https?):\/\/)(www.)?(niche)\./, //https://www.niche.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.niche.'
  },
  {
    value: 'OpenRice',
    title: 'OpenRice',
    pattern: /^((https?):\/\/)(www.)?(openrice)\./, //https://www.openrice.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.openrice.'
  },
  {
    value: 'Opentable',
    title: 'Opentable',
    pattern: /^((https?):\/\/)(www.)?(opentable)\./, //https://www.opentable.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.opentable.'
  },
  {
    value: 'ProductHunt',
    title: 'ProductHunt',
    pattern: /^((https?):\/\/)(www.)?(producthunt)\./, //https://www.producthunt.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.producthunt.'
  },
  {
    value: 'productreview',
    title: 'ProductReview.com.au',
    pattern: /^((https?):\/\/)(www.)?(productreview)\./, //https://www.productreview.com.au/
    isAdditionalSource: true,
    validatedUrl: 'https://www.productreview.'
  },
  {
    value: 'RateMDs',
    title: 'RateMDs',
    pattern: /^((https?):\/\/)(www.)?(ratemds)\./, //https://www.ratemds.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.ratemds.'
  },
  {
    value: 'Sitejabber',
    title: 'Sitejabber',
    pattern: /^((https?):\/\/)(www.)?(sitejabber)\./, //https://www.sitejabber.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.sitejabber.'
  },
  {
    value: 'SoftwareAdvice',
    title: 'SoftwareAdvice',
    pattern: /^((https?):\/\/)(www.)?(softwareadvice)\./, //https://www.softwareadvice.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.softwareadvice.'
  },
  {
    value: 'The Knot',
    title: 'The Knot',
    pattern: /^((https?):\/\/)(www.)?(theknot)\./, //https://www.theknot.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.theknot.'
  },
  {
    value: 'Thumbtack',
    title: 'Thumbtack',
    pattern: /^((https?):\/\/)(www.)?(thumbtack)\./, //https://www.thumbtack.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.thumbtack.'
  },
  {
    value: 'Trulia',
    title: 'Trulia',
    pattern: /^((https?):\/\/)(www.)?(trulia)\./, //https://www.trulia.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.trulia.'
  },
  {
    value: 'TrustedShops',
    title: 'TrustedShops',
    pattern: /^((https?):\/\/)(www.)?(trustedshops)\./, //http://www.trustedshops.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.trustedshops.'
  },
  {
    value: 'Trustpilot',
    title: 'Trustpilot',
    pattern: /^((https?):\/\/)(www.)?(trustpilot)\./, //https://www.trustpilot.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.trustpilot.'
  },
  {
    value: 'TrustRadius',
    title: 'TrustRadius',
    pattern: /^((https?):\/\/)(www.)?(trustradius)\./, //https://www.trustradius.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.trustradius.'
  },
  {
    value: 'Vitals',
    title: 'Vitals',
    pattern: /^((https?):\/\/)(www.)?(vitals)\./, //https://www.vitals.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.vitals.'
  },
  {
    value: 'Walmart',
    title: 'Walmart',
    pattern: /^((https?):\/\/)(www.)?(walmart)\./, //https://www.walmart.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.walmart.'
  },
  {
    value: 'WeddingWire',
    title: 'WeddingWire',
    pattern: /^((https?):\/\/)(www.)?(weddingwire)\./, //https://www.weddingwire.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.weddingwire.'
  },
  {
    value: 'Yell',
    title: 'Yell',
    pattern: /^((https?):\/\/)(business.)?(yell)\./, //https://business.yell.com/
    isAdditionalSource: true,
    validatedUrl: 'https://business.yell.'
  },
  {
    value: 'YellowPages',
    title: 'YellowPages',
    pattern: /^((https?):\/\/)(www.)?(yellowpages)\./, //	https://www.yellowpages.com
    isAdditionalSource: true,
    validatedUrl: 'https://www.yellowpages.'
  },
  {
    value: 'ZocDoc',
    title: 'ZocDoc',
    pattern: /^((https?):\/\/)(www.)?(zocdoc)\./, //https://www.zocdoc.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.zocdoc.'
  },
  {
    value: 'menupix',
    title: 'Menupix',
    pattern: /^((https?):\/\/)(www.)?(menupix)\./, //https://www.menupix.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.menupix.'
  },
  {
    value: 'restomontreal',
    title: 'RestoMontreal',
    pattern: /^((https?):\/\/)(www.)?(restomontreal)\./, //https://www.restomontreal.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.restomontreal.'
  },
  {
    value: 'restoquebec',
    title: 'RestoQuebec',
    pattern: /^((https?):\/\/)(www.)?(restoquebec)\./, //https://www.restoquebec.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.restoquebec.'
  },
  {
    value: 'bookenda',
    title: 'Bookenda',
    pattern: /^((https?):\/\/)(www.)?(bookenda)\./, //https://www.bookenda.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.bookenda.'
  },
  {
    value: 'grubhub',
    title: 'Grubhub',
    pattern: /^((https?):\/\/)(www.)?(grubhub)\./, //https://www.grubhub.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.grubhub.'
  },
  {
    value: 'doordash',
    title: 'DoorDash',
    pattern: /^((https?):\/\/)(www.)?(doordash)\./, //https://www.doordash.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.doordash.'
  },
  {
    value: 'skipthedishes',
    title: 'SkipTheDishes',
    pattern: /^((https?):\/\/)(www.)?(skipthedishes)\./, //https://www.skipdthedishes.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.skipthedishes.'
  },
  {
    value: 'chatfood',
    title: 'Chatfood',
    pattern: /^((https?):\/\/)(www.)?(chatfood)\./, //https://www.chatfood.com/
    alternatePattern: /^(https:\/\/)?dashboard\.chatfood\.io\/business\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\/outlets\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\/?$/,
    isAdditionalSource: true,
    validatedUrl: 'https://www.chatfood.',
    validateAlternateUrl: 'https://dashboard.chatfood.io/busines/{business-id}/outlets/{outlet-id}/'
  },
  {
    value: 'noon',
    title: 'Noon',
    pattern: /^((https?):\/\/)(www.)?(noon)\./, //https://www.noon.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.noon.'
  },
  {
    value: 'munchon',
    title: 'Munchon',
    pattern: /^((https?):\/\/)(www.)?(munchon)\./, //https://www.munchon.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.munchon.'
  },
  {
    value: 'ritual',
    title: 'Ritual',
    pattern: /^((https?):\/\/)(www.)?(store.ritual)\./, // https://ritual.co/
    isAdditionalSource: true,
    validatedUrl: 'https://store.ritual.'
  },
  {
    value: 'takeaway',
    title: 'TakeAway',
    pattern: /^((https?):\/\/)(www.)?(takeaway)\./, // https://www.takeaway.com/
    isAdditionalSource: true,
    validatedUrl: 'https://www.takeaway.'
  },
  {
    value: 'wolt',
    title: 'Wolt',
    pattern: /^((https?):\/\/)(www.)?(wolt)\./, // https://wolt.com/
    isAdditionalSource: true,
    validatedUrl: 'https://wolt.'
  },
  {
    value: 'bolt',
    title: 'Bolt',
    pattern: /^((https?):\/\/)(www.)?(bolt)\./, // https://bolt.eu/
    isAdditionalSource: true,
    validatedUrl: 'https://bolt.'
  },
  {
    value: 'thechefz',
    title: 'TheChefz',
    pattern: /^((https?):\/\/)(www.)?(thechefz)\./, // https://thechefz.co/
    isAdditionalSource: true,
    validatedUrl: 'https://thechefz.'
  },
  {
    value: 'jahez',
    title: 'Jahez',
    pattern: /^((https?):\/\/)(www.)?(jahez)\./, // https://www.jahez.net/
    isAdditionalSource: true,
    validatedUrl: 'https://www.jahez.'
  },
  {
    value: 'mrsool',
    title: 'Mrsool',
    pattern: /^((https?):\/\/)(www.)?(mrsool)\./, // https://mrsool.co/
    isAdditionalSource: true,
    validatedUrl: 'https://mrsool.'
  },
  {
    value: 'toyou',
    title: 'ToYou',
    pattern: /^((https?):\/\/)(www.)?(toyou)\./, // https://toyou.io/
    isAdditionalSource: true,
    validatedUrl: 'https://toyou.'
  },
  {
    value: 'shgardi',
    title: 'Shgardi',
    pattern: /^((https?):\/\/)(www.)?(shgardi)\./, // https://shgardi.app/
    isAdditionalSource: true,
    validatedUrl: 'https://shgardi.'
  },
  {
    value: 'lugmety',
    title: 'Lugmety',
    pattern: /^((https?):\/\/)(www.)?(lugmety)\./, // https://lugmety.com/
    isAdditionalSource: true,
    validatedUrl: 'https://lugmety.'
  },
  {
    value: 'restoguru',
    title: 'RestoGuru',
    pattern: /^((https?):\/\/)(www.)?(resto)\./, // https://resto.guru/
    isAdditionalSource: true,
    validatedUrl: 'https://resto.'
  },
  {
    value: 'yemeksepeti',
    title: 'Yemeksepeti',
    pattern: /^((https?):\/\/)(www.)?(yemeksepeti)\./, // https://yemeksepeti.com/
    isAdditionalSource: true,
    validatedUrl: 'https://yemeksepeti.'
  },
];

const SERVICES_FOR_PARSING = SERVICES.filter(
  service => service.value !== 'feedback' && service.value !== 'ubereats' && service.value !== 'deliveroo'
);
const DEFAULT_COMPANY_SOURCES = [
  'Google', //+
  'Facebook', //+
  'Foursquare', //-
  'Zomato', //+
  'TripAdvisor', //+
  'Talabat', //-
  'HungerStation', //-
  'ReserveOut', //-
  'Booking', //+
  'Expedia', //-
  'Deliveroo', //-
  'UberEats', //-
  'Feedback' //+
];
// const acceptableServicesToAnswer = ['google', 'tripadvisor', 'zomato', 'facebook', 'feedback', 'booking'];

const ADITIONAL_COMPANY_SOURCES = [
  'Agoda',
  'Avvo',
  'Google Playstore',
  'Google Shopping',
  'Yelp',
  'Zillow',
  'Airbnb',
  'AliExpress',
  'AlternativeTo',
  'Amazon US',
  'Amazon France',
  'Apartmentratings',
  'Apartments',
  'Apple Appstore',
  'BBB',
  'Bilbayt',
  'BookATable',
  'Capterra',
  'CarGurus',
  'Careem NOW',
  'Cars.com',
  'Citysearch',
  'Classpass',
  'Consumer Affairs',
  'CreditKarma',
  'CustomerLobby',
  'DealerRater',
  'Ebay',
  'Edmunds',
  'Etsy',
  'FindLaw',
  'G2Crowd',
  'Gartner',
  'Glassdoor',
  'Greatschools',
  'Healthgrades',
  'HomeAdvisor',
  'HomeAway',
  'Homestars',
  'Houzz',
  'Hotels.com',
  'Indeed',
  'Influenster',
  'Insider Pages',
  'IT Central Station',
  'Lawyers.com',
  'Lending Tree',
  'Martindale',
  'Niche',
  'OpenRice',
  'Opentable',
  'ProductHunt',
  'ProductReview.com.au',
  'RateMDs',
  'Sitejabber',
  'SoftwareAdvice',
  'The Knot',
  'Thumbtack',
  'Trulia',
  'TrustedShops',
  'Trustpilot',
  'TrustRadius',
  'Vitals',
  'Walmart',
  'WeddingWire',
  'Yell',
  'YellowPages',
  'ZocDoc',
  'Menupix',
  'SkipTheDishes',
  'DoorDash',
  'Grubhub',
  'Bookenda',
  'RestoQuebec',
  'RestoMontreal',
  'Noon',
  'Munchon',
  'Chatfood',
  'Ritual',
  'TakeAway',
  'Wolt',
  'Bolt',
  'TheChefz',
  'Jahez',
  'Mrsool',
  'ToYou',
  'Shgardi',
  'Lugmety',
  'RestoGuru',
  'Yemeksepeti'
];

const SERVICES_WITH_MULTIPLE_ACCOUNTS = ['chatfood', 'ubereats', 'doordash', 'hungerstation', 'deliveroo'];

const COMPANY_SOURCES_WITHOUT_REPLY = [
  'AliExpress',
  'AlternativeTo',
  'BookATable',
  'Consumer Affairs',
  'CreditKarma',
  'Foursquare',
  'Gartner',
  'Greatschools',
  'HomeAdvisor',
  'Influenster',
  'Lawyers.com',
  'Niche',
  'OpenRice',
  'ProductHunt',
  'Talabat',
  'YellowPages',
  'HungerStation',
  'ReserveOut',
  'Menupix',
  'Bookenda',
  'Grubhub',
  'Careem NOW',
  'Noon',
  'Munchon',
  'Chatfood',
  'Ritual',
  'TakeAway',
  'Wolt',
  'Bolt',
  'TheChefz',
  'Jahez',
  'Mrsool',
  'ToYou',
  'Shgardi',
  'Lugmety',
  'RestoGuru'
];
export {
  SERVICES,
  SERVICES_FOR_PARSING,
  DEFAULT_COMPANY_SOURCES,
  COMPANY_SOURCES_WITHOUT_REPLY,
  ADITIONAL_COMPANY_SOURCES,
  SERVICES_WITH_MULTIPLE_ACCOUNTS
};
